import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import { useSecurityStore } from "@/stores/security"
import {
  login,
  getUserInfo,
  handleCallbackAuthEvent,
  getAuthState,
  removeSubRoutes,
} from "@/auth/authFunctions"
import { getArrayOfValidatedApps, createMenu } from "@/router/routerFuncs"
import {
  submoduleRoutes,
  submoduleGuards,
  submoduleMenuInfo,
} from "./submoduleRoutes.js" // Import dynamically generated routes, menuInfo, guards

// Define existing routes
let routes: Array<RouteRecordRaw> = [
  ...submoduleRoutes,
  {
    path: "/",
    name: "homepage",
    component: () => import("@/pages/HomePage.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/pages/Login.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/pages/Register.vue"),
  },
  {
    path: "/error-page",
    name: "error-page",
    component: () => import("@/pages/ErrorPage.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import("@/pages/PageNotFound.vue"),
    meta: { notFound: true },
  },
]
console.log("routes", routes)
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

router.beforeEach(async (to, from, next) => {
  console.log(`Navigating from ${from.path} to ${to.path}`)
  // changes the document title to possible route name set in each submodule router file
  let title = "TM"
  to.matched.forEach((record) => {
    if (record.meta?.title) {
      title = title + " - " + record.meta?.title
    } else {
      for (const child of record.children) {
        if (child.meta?.title && child.path === "") {
          title = title + " - " + child.meta?.title
          break
        }
      }
    }
  })
  if (title === "TM") {
    title = "TM Portal"
  }

  document.title = title
  // catch error - no further logic for error events
  if (to.name === "error-page") {
    next()
    return
  }

  // priority ordering here - always handle callback first
  // then handle auth check on all routes but home page

  // handle auth sign in callback flow
  // captures callback on any route as long as code and state are params
  // state and code params removed here also
  // captures callback on any route as long as code and state are params
  // state and code params removed here also
  if (to.query.code && to.query.state) {
    console.log("Auth callback detected")
    let callbackResult = await handleCallbackAuthEvent(
      to.query.code,
      to.query.state
    )
    // if error
    if (callbackResult.error) {
      next({ name: "error-page" })
    }
    // if valid then nav to homepage or previous context
    // menu building and route mngmn will be handled in the callback function
    next(callbackResult)
    return // exit function - prevent calls in next block
  }

  // now handle browser logged in state
  // confirmation of matching server state
  const securityStore = useSecurityStore()
  if (securityStore.loggedIn) {
    // store last route after error and callback events and loggedIn is true
    sessionStorage.setItem("postLoginRedirectFull", to.fullPath)
    console.log("logged in in state detected")
    // browser is logged in
    // call the server on every event
    // check server - this is a double check in backend - prevents any client side tampering
    getUserInfo()
    // resuming session or continuing current session to new route success
    // now 1. remove unauthorized routes (if needed)
    // 2. build menu items (if they don't exist)
    let validApps = await getArrayOfValidatedApps()
    createMenu(submoduleMenuInfo, validApps)
    console.log("next happening")
    next()
  } else if (!securityStore.loggedIn) {
    // although not logged in in browser , check if session exists in backend
    let authState = await getAuthState()
    if (authState.error) {
      next({ name: "error-page" })
    }
    // if true then update routes and store data etc - silent background login update event - httponly cookie updates also
    if (authState.authenticated === true) {
      // store last route after error and callback events and loggedIn is true
      sessionStorage.setItem("postLoginRedirectFull", to.fullPath)
      // stores available apps for user and other data from backend
      await securityStore.setUserState(authState.userData)
      // now 1. remove unauthorized routes (if needed)
      // 2. build menu items
      let validApps = await getArrayOfValidatedApps()
      createMenu(submoduleMenuInfo, validApps)
      next()
    }
    // no session in server - redirect to login if not home page
    else if ((authState.authenticated === false && to.name) === "homepage") {
      // allow nav to main route
      next()
    } else {
      // redirect to login for any other route
      login()
    }
  } else {
    // error - not able to get logged in status
    console.log("error - api/server issue")
    next({ name: "error-page" })
  }
})

// Applying each submodule's route guard - needs to be done after the main router guard instantiated above
Object.values(submoduleGuards).forEach((guard) => {
  router.beforeEach(guard(router))
})

export default router
