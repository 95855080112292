import {
  genericRouteGuardResult,
  parseUrlStringForAppId,
} from "@/router/routerFuncs"

const routes = [
  {
    path: "/wifipkgmgmnt",
    component: () => import("@apps/wifipkgmgmnt/src/App.vue"),
    children: [
      {
        path: "",
        name: "wifipkgmgmnt_table",
        meta: { title: "Wi-Fi Package Management" },
        component: () =>
          import("@apps/wifipkgmgmnt/src/pages/wifipkgmgmnt_table.vue"),
      },
      {
        path: "single",
        name: "wifipkgmgmnt_single",
        meta: { title: "Edit Single" },
        component: () =>
          import("@apps/wifipkgmgmnt/src/pages/wifipkgmgmnt_single.vue"),
      },
      {
        path: "create_single",
        name: "wifipkgmgmnt_createSingle",
        meta: { title: "Create Single" },
        component: () =>
          import("@apps/wifipkgmgmnt/src/pages/wifipkgmgmnt_createSingle.vue"),
      },
      {
        path: "log",
        name: "wifipkgmgmnt_log",
        meta: { title: "Log" },
        component: () =>
          import("@apps/wifipkgmgmnt/src/pages/wifipkgmgmnt_log.vue"),
      },
    ],
  },
]

const menuIndex = 1

const menuItems = [
  {
    icon: "Wifi",
    pageName: "wifipkgmgmnt_table",
    title: "Wi-Fi Package Management",
    /* subMenu: [
      {
        icon: "Bell",
        pageName: "wifiPkgMgmnt_home",
        title: "Get started",
      },
      {
        icon: "Bell",
        pageName: "wifiPkgMgmnt_table",
        title: "Packages",
      },
    ], */
  },
]

export const submoduleRouteGuard = (router: any) => {
  return async (to: any, from: any, next: any) => {
    // step 1  - catch all submodule route events - must allow navigation with next() call if not this submodule
    let guardRequirements = {
      appId: "wifipkgmgmnt",
      baseRoles: ["wifipkgmgmnt-basic"],
    }
    let parsedAppID: string | null = parseUrlStringForAppId(to.path)
    if (parsedAppID === guardRequirements.appId) {
      // step 2 - inside of this submodule - check if the role is allowed/valid
      const routeGuardAllowed = await genericRouteGuardResult(guardRequirements)
      if (routeGuardAllowed) {
        next()
      } else {
        // abort navigation
        next("/") // Redirect to home page - show no access message
      }
    } else {
      next() // Allow other submodule routes and routes
    }
  }
}

export default {
  routes,
  menuIndex,
  menuItems,
}
