<script setup lang="ts">
import { computed, watch, ref, type Ref, onMounted } from "vue"
import Lucide from "@/components/Base/Lucide"
import Breadcrumb from "@/components/Base/Breadcrumb"
import { RouterLink, useRoute, useRouter } from "vue-router"
import { useThemeStore } from "@/stores/theme"
import UserDropdown from "@/components/Custom/Theme/UserDropdown.vue"
import MobileMenu from "@/components/MobileMenu/MobileMenu.vue"
import { useMenuStore } from "@/stores/menu"

const themeStore = useThemeStore()
const theme = computed(() => themeStore.themeValue.name)
const menuStore = useMenuStore()

const route = useRoute()

type Breadcrumbs = {
  name: string
  params: any
  metaName: string
}

const breadcrumbSegments = computed(() => {
  const path = route.path
  const segments = path
    .split("/")
    .filter(Boolean)
    .map((segment) => segment.charAt(0).toUpperCase() + segment.slice(1))

  if (segments[0] === "Wifipkgmgmnt") {
    menuStore.menuValue.filter((item) => {
      if (item.pageName === "wifipkgmgmnt_table") {
        segments[0] = item.title
      }
    })
  }
  return segments
})

const breadcrumbs: Ref<Breadcrumbs[]> = ref([])

const createCrumb = (routeData, routeParams) => {
  // Initialize a crumb object with default values
  let crumb = { name: "", params: {}, metaName: "" }

  // Check if the route path contains parameters
  if (routeData.path.includes(":")) {
    let bananaSplit = routeData.path.split("/")

    // Extract parameters from the route path and assign them to the crumb
    for (let j = 0; j < bananaSplit.length; j++) {
      if (bananaSplit[j].includes(":")) {
        crumb.params[bananaSplit[j].replace(":", "")] =
          routeParams[bananaSplit[j].replace(":", "")]
      }
    }
  }
  return crumb
}

const handleRouteWithParams = (routeData, crumb) => {
  // Handle routes that have parameters in their path
  if (routeData.name === undefined) {
    // If the route has no name, find the first child route with an empty path
    for (let j = 0; j < routeData.children.length; j++) {
      if (routeData.children[j].path?.length === 0) {
        crumb.name = routeData.children[j].name
        crumb.metaName =
          routeData.children[j].meta?.title ||
          routeData.children[j].path.split("/").pop().replace(":", " ")
        break
      }
    }
  } else {
    // If the route has a name, use it and set the metaName
    crumb.name = routeData.name
    crumb.metaName =
      routeData.meta?.title ||
      firstCharacterToUpperCase(routeData.path.split("/").pop())
  }
  return crumb
}

const handleRouteWithoutParams = (routeData, crumb) => {
  // Handle routes that do not have parameters in their path
  if (routeData.name === undefined) {
    // If the route has no name, find the first child route with an empty path
    for (let j = 0; j < routeData.children.length; j++) {
      if (routeData.children[j].path?.length === 0) {
        crumb.name = routeData.children[j].name
        crumb.metaName =
          routeData.children[j].meta?.title ||
          routeData.children[j].path.split("/").pop().replace(":", " ")
        break
      }
    }
  } else {
    // If the route has a name, use it and set the metaName
    crumb.name = routeData.name
    crumb.metaName =
      routeData.meta?.title ||
      firstCharacterToUpperCase(routeData.path.split("/").pop())
  }
  return crumb
}

const createBreadcrumbs = () => {
  console.log("route", route)
  const routeData = route.matched
  const routeParams = route.params

  // Iterate through each route in the matched routes
  for (let i = 0; i < routeData.length; i++) {
    // Create a crumb for the current route
    let crumb = createCrumb(routeData[i], routeParams)

    // Handle the route based on whether it has parameters or not
    if (routeData[i].path.includes(":")) {
      crumb = handleRouteWithParams(routeData[i], crumb)
    } else {
      crumb = handleRouteWithoutParams(routeData[i], crumb)
    }

    // Add the crumb to the breadcrumbs array if it is valid and not already present
    if (
      crumb.name !== "" &&
      crumb.metaName !== "" &&
      crumb.name !== undefined &&
      crumb.metaName !== undefined &&
      breadcrumbs.value.findIndex((element) => element.name === crumb.name) ===
        -1
    ) {
      breadcrumbs.value.push(crumb)
    }
  }
  console.log("breadcrumbs:", breadcrumbs.value)
}

const firstCharacterToUpperCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

watch(route, () => {
  breadcrumbs.value = []
  createBreadcrumbs()
})

onMounted(() => {
  createBreadcrumbs()
})
</script>

<template>
  <!-- Enigma -->
  <MobileMenu />

  <div
    class="relative z-[51] flex h-10 w-full items-center justify-end border-b border-slate-200 bg-gradient-to-r from-gray-100 from-0% via-gray-100/70 via-50% to-gray-100 to-100% px-4 backdrop-blur-lg dark:bg-gradient-to-r dark:from-darkmode-700 dark:via-darkmode-700/70 dark:to-darkmode-700 md:sticky md:top-0 md:h-[67px]"
  >
    <div :class="theme === 'tinker' ? 'flex w-full' : ''">
      <!-- BEGIN: Logo -->

      <!-- END: Logo -->
      <!-- BEGIN: Breadcrumb -->
      <Breadcrumb light class="-intro-x mr-auto flex text-sm md:text-sm">
        <Breadcrumb.Link class="text-primary dark:text-light" to="/">
          Home
        </Breadcrumb.Link>
        <div
          v-if="breadcrumbs.length > 2"
          class="flex items-center justify-center text-primary dark:text-light lg:hidden"
        >
          <Lucide icon="ChevronRight" class="h-4 w-4" />...
        </div>
        <div
          v-for="(element, index) in breadcrumbs"
          class="flex items-center justify-center text-sm text-primary dark:text-light"
          :class="breadcrumbs.length - 2 > index ? 'hidden lg:flex' : ''"
        >
          <RouterLink
            :to="{
              name: element.name,
              params: element.params,
            }"
          >
            <div class="flex items-center text-sm">
              <Lucide icon="ChevronRight" class="h-4 w-4" />

              {{
                element.metaName?.slice(0, 12) +
                (element.metaName?.length > 12 ? "..." : "")
              }}
            </div>
          </RouterLink>
        </div>
      </Breadcrumb>
      <!-- Home
        </Breadcrumb.Link>
        <div
          v-if="breadcrumbSegments.length > 2"
          class="flex items-center justify-center text-primary dark:text-light lg:hidden"
        >
          <Lucide icon="ChevronRight" class="h-4 w-4" />...
        </div>
        <div
          v-for="(element, index) in breadcrumbSegments"
          class="flex items-center justify-center text-sm text-primary dark:text-light"
          :class="breadcrumbSegments.length - 2 > index ? 'hidden lg:flex' : ''"
        >
          <Lucide icon="ChevronRight" class="h-4 w-4" />
        
          {{ element.slice(0, 12) + (element.length > 12 ? "..." : "") }}
   
        </div>
      </Breadcrumb> -->

      <!-- END: Breadcrumb -->

      <UserDropdown class="hidden md:block" />
    </div>
  </div>
</template>
